.iframeWrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 44px;
  padding-top: 62.5%;
  position: relative;
}
.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 30px 44px 30px;
  iframe {
    border: none;
  }
}
.admin {
  .iframeWrapper {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 28px;
    padding-top: 50.5%;
    position: relative;
  }
  .videoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0px 1px 29px 2px;
  }
}
iframe {
  width: 100% !important;
  height: 100% !important;
}
