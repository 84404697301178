@import "../../../../scss/respond-to.scss";
.pickNumberWrapper {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .pickedNumber {
    font-weight: bold;
    font-size: 42px;
    line-height: 60px;
    /* identical to box height */

    color: #a0ebff;
    .numberNotSelected {
      padding-bottom: 4px;
    }
  }
  .numbersContainer {
    display: flex;
    justify-content: center;
    .numbers {
      padding-top: 23px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      min-width: 104%;
      max-width: 116px;
      @include respond-to("mobileAll") {
        min-width: unset;
        max-width: 120px;
      }
      @include respond-to("tablet") {
        max-width: 108px;
      }
      .number {
        display: flex;
        justify-content: center;
        cursor: pointer;
        font-family: Helvetica Now Text;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */
        color: #ffffff;
        padding: 2px;
        padding-top: 8px;
      }
    }
  }
  .active {
    background: #ffc107;
  }
}
@include respond-to("mobileAll") {
  .pickNumberWrapper {
    width: calc(50% - 24px);
    margin-top: 35px;
  }
}
@include respond-to("tablet") {
  .pickNumberWrapper {
    .numbers {
      width: 100%;
      padding-top: 19px;
      .number {
        padding-left: 4px;
      }
    }
  }
}
.gif {
  width: 125px;
  height: 125px;
}
