@import "../../../../scss/respond-to.scss";
.cardContainer {
  background: url("../../../../asserts/images/status-finished-card.svg");
  margin-top: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-size: cover;
  padding-top: 40.9%;
  .sum {
    position: absolute;
    top: 20px;
    left: 27px;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 33px;
    /* identical to box height */

    color: #000000;
  }
  .tickets {
    display: flex;
    position: absolute;
    top: 67px;
    left: 22px;

    .ticket {
      margin-left: 5px;
      color: white;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 52.56px;
      height: 52.56px;
      background: linear-gradient(270.14deg, #119490 0.1%, #16a49e 98.75%);
      box-shadow: 0px 2.23181px 26.7817px rgba(0, 0, 0, 0.15);
      border-radius: 111.59px;
      @include respond-to("mobileAll") {
        width: 39.56px;
        height: 39.56px;
      }
    }
  }
}
