@import "../../../scss/respond-to.scss";
.statistic {
  padding-left: 27px;
  padding-top: 31px;
  color: #ffffff;
  padding-bottom: 169px;
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }
  &__item {
    padding-top: 30px;
    display: flex;
    .image {
    }
    .data {
      font-family: "Gordita", sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 26px;
      padding-left: 19px;
    }
  }
}
@include respond-to("mobileAll") {
  .statistic {
    padding-bottom: 57px;
  }
}
