$white: #ffff;

//Admin
$green: #16a49e;
$black: #000000;
$text-color: #212121;
$blue: #a0ebff;
$border-color: #ededed;

$button-1: #119490;
$button-shadow: rgba(0, 0, 0, 0.15);

//Client
