@import "../../../scss/variables/color-plate.scss";
@import "../../../scss/respond-to.scss";
.nextGameWrapper {
  padding: 30px;
  @include respond-to("tablet") {
    display: flex;
    justify-content: space-around;
  }
  .head {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      display: flex;
      div:nth-child(2) {
        padding-left: 7px;
        padding-top: 2px;
      }
      /* identical to box height */
      color: rgba(255, 255, 255, 0.45);
    }
  }
  .aboutGame {
    color: $white;
    font-family: "Gordita", sans-serif;
    margin-top: 30px;
    .date {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */

      text-transform: capitalize;
    }
    .amount {
      font-style: normal;
      font-weight: bold;
      font-size: 44px;
      line-height: 63px;
    }
    .buttonWrapper {
      margin-top: 11px;
    }
  }
  .myTikcets {
    margin-top: 36px;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height */
      color: $white;
    }
  }
}
