@import "../../../../../scss/respond-to.scss";
.UpcomingGames {
  .title {
    margin-top: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 10px;
    color: #ffffff;
    margin-bottom: 27px;
  }
}