.c-transition--fade-enter-active,
.c-transition--fade-leave-active {
  transition: opacity .3s ease-out;
}

.c-transition--fade-leave-active,
.c-transition--flip-leave-active,
.c-transition--jump-down-leave-active,
.c-transition--jump-left-leave-active,
.c-transition--jump-right-leave-active,
.c-transition--jump-up-leave-active,
.c-transition--rotate-leave-active,
.c-transition--scale-leave-active,
.c-transition--slide-down-leave-active,
.c-transition--slide-left-leave-active,
.c-transition--slide-right-leave-active,
.c-transition--slide-up-leave-active {
  position: absolute;
}

.c-transition--fade-enter-to,
.c-transition--fade-leave-active {
  transition: opacity .3s ease-out;
}

.c-transition--fade-enter, .c-transition--fade-leave, .c-transition--fade-leave-to {
  opacity: 0;
}

// c-transition--fade-leave-to c-transition--fade-leave-active
// c-transition--fade-enter-to c-transition--fade-enter-active