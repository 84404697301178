@import "../../../../scss/variables/color-plate.scss";
.CreateEditGameModal {
  background: $white;
  padding: 35px;
  padding-bottom: 47px;
  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: $text-color;
    margin-bottom: 34px;
  }
  .row {
    margin-bottom: 26px;
    .ADateTime {
      width: 100%;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    .save,
    .publish {
      width: calc(50% - 4px);
    }
  }
}
.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
}
.pointer {
  cursor: pointer;
  font-size: 13px;
}
.checkbox {
  &:checked {
    background-color: red;
    color: red;
    fill: red;
  }
  font-size: 13px;
}
