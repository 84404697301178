@import "../../../scss/respond-to.scss";

.profileSettingsWrapper {
  margin: 0 auto;
  width: 389px;
  @include respond-to("mobileAll") {
    width: auto;

  }
  .item {
    margin-top: 25px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
    .title {
      padding-bottom: 5px;
    }
    .subtitle {
      font-family: "Gordita", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */
      .yellow {
        color: yellow !important;
      }
      color: rgba(255, 255, 255, 0.45);
    }
    color: #ffffff;
  }
  .buttonWrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.respondTo {
  .form {
    @include respond-to("mobileAll") {
      padding-left: 26px;
      padding-right: 26px;
    }
  }
}
.imageHelperError {
  padding-top: 15px;
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #ff5353;
}
