.confrimWrapper {
}
.layout {
  padding: 30px;
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  padding-bottom: 5px;
  color: #ffffff;
  .actions {
    padding-top: 30px;
    display: flex;
    padding-bottom: 30px;
    justify-content: space-between;
  }
  .close {
    cursor: pointer;
    font-style: normal;
    // font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    width: 143px;
    height: 38px;
    background: linear-gradient(270.14deg, #119490 0.1%, #16a49e 98.75%);
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .resend {
    text-decoration: underline;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    color: aqua;
    cursor: pointer;
  }
}
.successResendButton {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.textCenter {
  text-align: center;
}
