@import "../scss/respond-to.scss";
.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  &.icon-arrow-back {
    width: 20.75px;
    height: 19.15px;
    background-image: url("../asserts/icons/icon-Arrow-back.svg");
  }
  &.icon-calendar {
    width: 18px;
    height: 20px;
    background-image: url("../asserts/icons/calendar.svg");
  }
}
.icon-diamond {
  background-image: url("../asserts/icons/diamond.svg");
  width: 15.23px;
  height: 12.73px;
}
.icon-add-to {
  background-image: url("../asserts/icons/addToCalendar.svg");
  width: 21px;
  height: 21px;
}
.iconArrow {
  width: 25.75px;
  height: 19.15px;
  background-image: url("../asserts/icons/iconArrow.svg");
}
.iconClose {
  background-image: url("../asserts/icons/icon-close.svg");
  height: 24px;
  width: 24px;
  position: relative;
  cursor: pointer;
  float: right;
  top: -25px;
  right: -34px;
}
.iconCloseAdmin {
  background-image: url("../asserts/icons/icon-close-admin.svg");
  height: 19px;
  width: 19px;
  position: relative;
  cursor: pointer;
  float: right;
  top: -60px;
  right: -61px;
}
.iconBucket {
  cursor: pointer;
  width: 62px;
  height: 61px;
  position: relative;
  right: 23px;
  top: -22px;
  background-image: url("../asserts/icons/icon-bucket.svg");
}
.how-to-play-icon {
  position: relative;
  top: 3px;
  width: 22px;
  height: 22px;
  background-image: url("../asserts/icons/how-to-play-icon.svg");
}
.icon-home {
  background-image: url("../asserts/icons/home.svg");
  width: 19px;
  height: 22px;
  margin-right: 10px;
}
.calendar-after {
  background-image: url("../asserts/icons/calendar-after.svg");
  width: 89.46px;
  height: 16.18px;
  top: 17px;
}
.menu-icon {
  width: 18px;
  height: 15px;
  position: relative;
  right: 10px;
  top: 4px;
  background-image: url("../asserts/icons/menu-icon.svg");
}
@include respond-to("mobileAll") {
  .iconClose {
    top: 50px;
    right: 36px;
    z-index: 1;
  }
}
@include respond-to("mobileRotate") {
  .iconClose {
    top: 40px;
    right: 36px;
  }
}
.admin-add-game {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../asserts/icons/admin-icon-add-game.svg");
}
.copy-icon {
  min-width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../asserts/icons/copyIcon.svg");
}
.prize-icon {
  width: 29px;
  height: 29px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../asserts/icons/prize-icon.svg");
  padding: 19px;
}
.profile-win-icon {
  width: 34px;
  height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../asserts/icons/profile-win-icon.svg");
}
.pl-26 {
  padding-left: 26px !important;
}
