.forgotPasswordWrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270.14deg, #119490 0.1%, #16a49e 98.75%);
  width: 85px;
  height: 31px;
  border-radius: 100px;
  float: right;
  position: relative;
  right: 16px;
  text-align: center;
  .forgotPassword {
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
    font-family: Gordita;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    padding: 4px;
    /* identical to box height */

    color: #ffffff;
  }
}
