$default_gap: 40px;
.row-grid-mb-1,
.row-grid-mb-2,
.row-grid-mb-3,
.row-grid-mb-4,
.row-grid-mb-5,
.row-grid-mb-6,
.row-grid-mb-rt-1,
.row-grid-mb-rt-2,
.row-grid-mb-rt-3,
.row-grid-mb-rt-4,
.row-grid-mb-rt-5,
.row-grid-mb-rt-6,
.row-grid-tb-1,
.row-grid-tb-2,
.row-grid-tb-3,
.row-grid-tb-4,
.row-grid-tb-5,
.row-grid-tb-6,
.row-grid-sm-1,
.row-grid-sm-2,
.row-grid-sm-3,
.row-grid-sm-4,
.row-grid-sm-5,
.row-grid-sm-6,
.row-grid-md-1,
.row-grid-md-2,
.row-grid-md-3,
.row-grid-md-4,
.row-grid-md-5,
.row-grid-md-6,
.row-grid-lg-1,
.row-grid-lg-2,
.row-grid-lg-3,
.row-grid-lg-4,
.row-grid-lg-5,
.row-grid-lg-6 {
  display: -ms-grid;
  display: grid;
  -ms-grid-gap: $default_gap 30px;
  grid-gap: $default_gap 40px;
  grid-auto-rows: auto;
  -ms-grid-auto-rows: auto;
  grid-template-rows: auto;
  -ms-grid-template-rows: auto;

  // -ms-grid-columns: 1fr 1fr 1fr 1fr;             /* adjusted */
  //     grid-template-columns:  repeat( 4, 1fr );
  // -ms-grid-rows: 270px 270px 270px 270px;        /* adjusted */
  //     grid-template-rows: repeat( 4, 270px );
  // grid-gap: 30px;
}
.row-grid-mb-1 {
  @include respond-to(mobile) {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}
.row-grid-mb-rt-1 {
  @include respond-to(mobileRotate) {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}
.row-grid-tb-1 {
  @include respond-to(tablet) {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}
.row-grid-sm-1 {
  @include respond-to(smallscreen) {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}
.row-grid-md-1 {
  @include respond-to(mediumscreen) {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}
.row-grid-lg-1 {
  @include respond-to(largescreen) {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}
.row-grid-mb-2 {
  @include respond-to(mobile) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}
.row-grid-mb-rt-2 {
  @include respond-to(mobileRotate) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}
.row-grid-tb-2 {
  @include respond-to(tablet) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}
.row-grid-sm-2 {
  @include respond-to(smallscreen) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}
.row-grid-md-2 {
  @include respond-to(mediumscreen) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}
.row-grid-lg-2 {
  @include respond-to(largescreen) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}
.row-grid-mb-3 {
  @include respond-to(mobile) {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}
.row-grid-mb-rt-3 {
  @include respond-to(mobileRotate) {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}
.row-grid-tb-3 {
  @include respond-to(tablet) {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}
.row-grid-sm-3 {
  @include respond-to(smallscreen) {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}
.row-grid-md-3 {
  @include respond-to(mediumscreen) {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}
.row-grid-lg-3 {
  @include respond-to(largescreen) {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}
.row-grid-mb-4 {
  @include respond-to(mobile) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
  }
}
.row-grid-mb-rt-4 {
  @include respond-to(mobileRotate) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
  }
}
.row-grid-tb-4 {
  @include respond-to(tablet) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
  }
}
.row-grid-sm-4 {
  @include respond-to(smallscreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
  }
}
.row-grid-md-4 {
  @include respond-to(mediumscreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
  }
}
.row-grid-lg-4 {
  @include respond-to(largescreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
  }
}
.row-grid-mb-5 {
  @include respond-to(mobile) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
}
.row-grid-mb-rt-5 {
  @include respond-to(mobileRotate) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
}
.row-grid-tb-5 {
  @include respond-to(tablet) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
}
.row-grid-sm-5 {
  @include respond-to(smallscreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
}
.row-grid-md-5 {
  @include respond-to(mediumscreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
}
.row-grid-lg-5 {
  @include respond-to(largescreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
}
.row-grid-mb-6 {
  @include respond-to(mobile) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
  }
}
.row-grid-mb-rt-6 {
  @include respond-to(mobileRotate) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
  }
}
.row-grid-tb-6 {
  @include respond-to(tablet) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
  }
}
.row-grid-sm-6 {
  @include respond-to(smallscreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
  }
}
.row-grid-md-6 {
  @include respond-to(mediumscreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
  }
}
.row-grid-lg-6 {
  @include respond-to(largescreen) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
  }
}
