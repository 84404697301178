@import "../../../scss/respond-to.scss";

.gameWinners {
  color: #ffffff;

  .title {
    padding-top: 31px;
    padding-left: 29px;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }
  .items {
    margin-top: 33px;
  }
  .item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    .left {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      padding-top: 7px;
      .index {
        color: #a7b4c4;
        padding-left: 15px;
      }
      .image {
        margin-left: 15px;
        width: 24px;
        height: 25px;

        img {
        }
      }
      .info {
        padding-left: 15px;
      }
    }
    .right {
      padding-bottom: 10px;
      padding-right: 15px;
      display: flex;
      color: #a0ebff;
      font-family: "Gordita", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      justify-content: center;
      align-items: center;
      padding-top: 7px;
      .icon {
        padding-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.winnersPage {
  .items {
    margin-top: 23px;
  }
  .title {
    @include respond-to("tablet") {
      padding-top: 0 !important;
    }
    padding-left: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    padding-bottom: 10px;
  }
  .item {
    margin-right: 0;
    margin-left: 0;
    border-bottom: none;
    margin-top: 25px;
    .left {
      .index {
        padding-left: 0;
      }
    }
    .right {
      padding-bottom: 0;
      padding-right: 0;
      padding-top: 6px;
      .icon-diamond {
        padding-bottom: 10px;
      }
      .icon {
        padding-bottom: 10px;
      }
    }
  }
}
.adminPage {
  .items {
    margin-top: 23px;
  }
  .item {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 14px;
    border-bottom: none;
    .right {
      justify-content: center;
      align-items: center;
    }
  }
}

@include respond-to("mobileAll") {
  .gameWinners {
  }
}
