.data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  border: 1px solid #ededed;
  cursor: pointer;
}
.label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
}
.value {
  width: 100%;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  padding: 16px 13px;
  min-height: 51px;
}
.icon {
  position: relative;
  right: 17px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 4px 0 4px;
  border-color: #000000 transparent transparent transparent;
}
.values {
  display: flex;
  position: relative;
  flex-direction: column;
  &__data {
    position: absolute;
    width: 100%;
    height: 242px;
    overflow: auto;
    &__value {
      background: white;
      width: 100%;
      border: none;
      box-sizing: border-box;
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      padding: 16px 13px;
      border-bottom: 1px solid #ededed;
      border-left: 1px solid #ededed;
      border-right: 1px solid #ededed;
      cursor: pointer;
    }
  }
}
.hidden {
  display: none;
}
.disabled {
  pointer-events: none;
}
