.buttonWrapper {
  outline: none;
  border: none;
  cursor: pointer;
  height: 77px;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */

  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(
    270deg,
    #f8ab2e 4.71%,
    #f8ab2e 4.71%,
    #ffd12f 85.88%
  );
  box-shadow: -9px 10px 22px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
}
.disabled {
  cursor: not-allowed;
  color: grey;
}
.border {
  border: 2px solid #a0ebff;
}