.resetWrapper {
  padding-top: 41px;
  padding-bottom: 41px;
  .title {
    color: white;
    font-weight: bold;
    font-weight: 800;
    font-size: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 31px;
    text-align: center;
  }
}
.mt41 {
  margin-top: 41px;
}
.userEmailWrapper {
  padding-left: 50px;
  padding-right: 50px;
}
