@import "../../scss/respond-to.scss";
@import "../../pages/Furtune/buyTicket/BuyTicket.module.scss";
.furtune {
  display: flex;
  width: 100%;
  font-family: "Gordita", sans-serif;
  &__ticketPicker {
    width: 69%;
    position: relative;
    .head {
      &.live {
        border: none;
      }
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      padding-bottom: 20px;

      .buttonBack {
        background: rgba(255, 255, 255, 0.12);
        border: 2px solid #9ae1f1;
        border-radius: 100px;
        display: flex;
        position: absolute;
        padding: 19px;
        left: 28px;
        top: 25px;
      }
      .title {
        text-align: center;
        width: 100%;
        padding-top: 45px;
        &__sub {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          text-transform: capitalize;

          color: #ffffff;
        }
        &__main {
          font-style: normal;
          font-weight: bold;
          font-size: 44px;
          line-height: 63px;
          /* identical to box height */

          color: #ffffff;
        }
      }

      .status {
        background: #25b5db;
        border-radius: 100px;
        max-height: 23px;
        position: absolute;
        top: 27px;
        right: 24px;
        white-space: nowrap;
        padding: 3px 10px 3px 10px;
        color: #ffffff;
        font-family: "Gordita", sans-serif;
      }
      .black {
        background: #131312;
      }
      .red {
        font-family: "Gordita", sans-serif;

        background: red;
        &:after {
          display: block;
          width: 4px;
          height: 4px;
          position: relative;
          background: white;
          content: "";
          top: 10px;
          left: 4px;
          border-radius: 12px;
          float: right;
        }
      }
    }
  }
  &__ticket {
    width: 30%;
    margin-left: 22px;
  }
  .myTicket {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 52px;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height */

      color: #ffffff;
    }
    .cardContainer {
      background: url("../../asserts/images/card.svg");
      margin-top: 30px;
      position: relative;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-size: contain;
      width: 100%;
      padding-top: 44.8%;
      .title {
        position: absolute;
        top: 18px;
        left: 18px;
      }
    }
  }
  .circlesIcon {
    background: url("../../asserts/icons/circles-2.svg");
    width: 48px;
    position: relative;
    top: 0px;

    width: 93%;
    height: 11px;
    &.none {
      background: none;
    }
  }
  .userNumbers {
    display: flex;
    position: absolute;
    left: 22px;
    bottom: 21px;
  }
  .number {
    font-weight: bold;

    color: #a0ebff;
  }
  .dotsContainer {
    position: absolute;
    $padding: 20;
    width: calc(100% - #{$padding * 2}px);
    left: #{$padding}px;
    right: #{$padding}px;

    top: calc(50% - 5px);
  }
  .circleWrapper {
    margin-right: 6px;
  }
  .cardSubTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    color: #ffffff;
  }
  .buttonWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
@include respond-to("mobileAll") {
  .furtune {
    flex-wrap: wrap;
    &__ticketPicker {
      width: 100%;
      position: relative;
      .head {
        .buttonBack {
        }
        .title {
          padding-top: 58px;
          &__sub {
          }
          &__main {
            font-size: 30px;
            line-height: 43px;
          }
        }

        .status {
          border-radius: 100px;
          max-height: 23px;
          position: absolute;
          top: 28px;
          left: 80px;
          /* right: 30px; */
          right: unset;
          white-space: nowrap;
          padding: 3px 10px 3px 10px;
          &__blue {
          }
          &__red {
            background: red;
          }
        }
      }
    }
    &__ticket {
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .myTicket {
      .title {
      }
      .cardContainer {
      }
    }

    .circlesIcon {
    }
    .userNumbers {
    }
    .number {
    }
    .dotsContainer {
    }
    .circleWrapper {
    }
    .cardSubTitle {
    }
    .buttonWrapper {
    }
  }
}
.ticketNotSelected {
  width: 37.5%;
}
.ticket {
  font-size: 20px;
  line-height: 28px;
  font-family: "Gordita", sans-serif;

  color: #ffffff;

  padding: 30px;
  &__game {
    .buttonWrapper {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
  &__video {
    .title {
      padding-bottom: 10px;
      margin-bottom: 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }
    margin-top: 42px;
  }
  &__winners {
    .title {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }
  }
}
.liveGamePage {
}
.disabled {
  cursor: not-allowed !important;
}
