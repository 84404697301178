@import "../../scss/variables/color-plate.scss";
@import "../../scss/respond-to.scss";
.calendar {
  &.admin {
    background: $white;
    border: 1px solid #ededed;
    box-sizing: border-box;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 31px;
    padding-right: 31px;
    .hide-admin {
      display: none;
    }
    .month {
      color: $text-color;
      svg {
        stroke: $text-color;
      }
    }
    td {
      padding-left: 1px;
      padding-right: 1px;
      cursor: pointer;
      .table-item-wrap {
        color: #ffffff;
        width: 35px;
        height: 35px;
        background: #dfdfdf;
      }
    }
    th {
      color: $text-color;
    }
  }
  &.admin-popup {
    max-width: 275px;
    background: $white;
    border: unset;
    box-sizing: border-box;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 0;
    padding-right: 0;
    .hide-admin {
      display: none;
    }
    .month {
      color: $text-color;
      svg {
        stroke: $text-color;
      }
    }
    td {
      padding-left: 1px;
      padding-right: 1px;
      cursor: pointer;
      .table-item-wrap {
        width: 35px;
        height: 35px;
        color: #ffffff;
        width: 35px;
        height: 35px;
        background: #dfdfdf;
      }
    }
    th {
      color: $text-color;
    }
  }
  table {
    margin-top: 26px;
    display: block;
    width: 100%;
    thead,
    tbody {
      width: 100%;
      display: block;
      tr {
        width: 100%;
        display: grid;
        grid-gap: 4px 4px;
        grid-template-columns: repeat(7, 1fr);
        td {
          position: relative;
          padding-top: 100%;
          >div {
            position: absolute !important;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
      }
    }
  }

  td {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 4px;
    padding-bottom: 5px;
    cursor: pointer;
  }
  th {
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */

    color: $white;
  }
  tr {
    margin-top: 9px;
  }
  td .table-item-wrap {
    &.event {
      background: #6490a0;
    }
    &.has-ticket {
      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 2px;
        right: 1px;
        width: 7px;
        height: 7px;
        background: #ffc107;
        border-radius: 50%;
      }
    }
    &.past-date {
      &::after {
        display: block;
        content: '';
        position: absolute;
        top: 2px;
        right: 1px;
        width: 7px;
        height: 7px;
        background: white;
        border-radius: 50%;
      }
    }
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */
    position: relative;
    top: 5px;
    color: $white;
    background: none;
    border: 0.869732px solid rgba(255, 255, 255, 0.49);
    color: rgba(255, 255, 255, 0.49);
    /* padding: 10px 15px 9px 15px; */
    border-radius: 1.73946px;
    // background: #6490a0;
    text-align: center;
    border-radius: 60.73946px;
  }
  .month {
    display: flex;
    justify-content: space-between;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    white-space: nowrap;
    /* identical to box height */

    color: $white;
  }
  .next {
    cursor: pointer;
  }
  .prev {
    cursor: pointer;
  }
  .calendar-buttons {
    display: flex;
    justify-content: space-between;
    width: 26px;
    min-width: 26px;
    margin-left: 10px;
  }
}
@include respond-to("mobileAll") {
  .calendar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: unset;
    padding-left: 30px;
    padding-right: 30px;
    .header {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 72%;
    }
    .month {
      width: 100%;
      justify-content: space-around;
    }
    table {
      width: 100%;
    }
  }
}
.selected {
  background: #ffc107 !important;
}
