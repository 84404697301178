@import "../../../scss/variables/color-plate.scss";
.myGamesWrapper {
  color: $white;
  padding-bottom: 20px;
  overflow-x: auto;
}

.cardContainer {
  background: url("../../../asserts/images/card.svg");
  margin-top: 20px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-size: cover;
  height: 151px;
  min-width: 337px;
  color: $white;
  &:not(:first-child) {
    margin-left: 20px;
  }
  .title {
    color: $blue;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    &__winData {
      display: flex;
      .data {
        font-family: Gordita;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */
        margin-left: 7px;
        color: #fbb72f;
        .paid {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
}
.dotsContainer {
  position: absolute;
  $padding: 20;
  width: calc(100% - #{$padding * 2}px);
  left: #{$padding}px;
  right: #{$padding}px;

  top: calc(50% - 4.5px);
}
.circleWrapper {
  margin-right: 5px;
}
.userNumbers {
  display: flex;
  position: absolute;
  left: 18px;
  bottom: 19px;
}
.cards {
  display: flex;
  width: max-content;
}
.gameWrapper {
  width: max-content;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 31px;
}
