.date {
  width: 120px;
}
.winner {
  width: 150px;
}
.email {
  width: 200px;
}
.amount {
  width: 150px;
}
.wallet {
  width: 120px;
  overflow: hidden;
}
.status {
  width: 120px;
}
.actions {
  width: 260px;
}

.PaginationContainer {
  margin-top: 15px;
}
.copyText {
  color: #16a49e;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
}
.noWallet {
  width: 17%;
  border-bottom: 1px solid;
  font-weight: bold;
}
