@import "../../scss/respond-to.scss";
.wrapper {
  padding: 30px;
  .title {
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
  }
  .item {
    margin-top: 30px;
  }
  .buttonWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 5px;
    }
  }
  .error {
    color: red;
    opacity: 1;
    height: 21px;

    &.show {
      height: 21px;
      opacity: 0;
    }
  }
}
.mainText {
  margin-top: 30px;
  color: white;
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-size: 20px;
}
@include respond-to("mobileAll") {
  .iconForgot {
    top: 32px;
  }
}
