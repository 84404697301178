.dots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dot {
    border: 1px solid #9ae1f1;
    border-radius: 20px;
    width: 9px;
    height: 9px;
  }
}
