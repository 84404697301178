@import "../../scss/variables/color-plate.scss";
.ticketWrapper {
  margin-top: 21px;
  &:first-child {
    margin-left: 0px;
  }
  .items {
    display: flex;
    margin-top: 14px;
  }
  .ticket {
    box-sizing: border-box;

    width: 63px;
    height: 63px;
    margin-left: 4px;
    border: 1px solid #9ae1f1;
    border-radius: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue;
    &__title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */

      color: rgba(255, 255, 255, 0.45);
    }
    background: rgba(255, 255, 255, 0.12);
  }
}
