.inputWrapper {
  width: 100%;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }

  input {
    background: rgba(0, 0, 0, 0.41);
    border: 1px solid #96dcf1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 60px;

    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
  }
}
.forgotPasswordWrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270.14deg, #119490 0.1%, #16a49e 98.75%);
  width: 85px;
  height: 31px;
  border-radius: 100px;
  float: right;
  position: relative;
  top: -45px;
  right: 18px;

  .forgotPassword {
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
    font-family: Gordita;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */

    color: #ffffff;
  }
}
