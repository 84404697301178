@import "../../../../scss/respond-to.scss";
.navbarWrapper {
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  @include respond-to("mobileAll") {
    padding-left: 15px;
    padding-right: 15px;
  }
  .buttons {
    display: flex;
    font-family: "Gordita", sans-serif;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height */
    > div {
      cursor: pointer;
    }
  }
  .singUp {
    margin-left: 1rem;
    display: flex;
    padding: 8px 18px 8px 18px;
    justify-content: center;
    border: 2px solid #a0ebff;

    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.75));
    border-radius: 100px;
    //   border-radius: 33px;
    color: #a0ebff;
    @include respond-to("mobileAll") {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  .logo {
    background: url("../../../../asserts/icons/logo.svg");
    width: 199.7px;
    height: 73px;
  }
  .text {
    color: #a0ebff;
  }
  .homeLink {
    display: flex;
    justify-content: center;
    color: white;
  }
}
.dropdown {
  background: linear-gradient(135.9deg, #558e98 15.86%, #2b2d59 85.14%);
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;  
  border: 2px solid #a0ebff;
  position: absolute;
  right: 0px;
  padding-left: 30px;
  padding-right: 30px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: 10px;
  z-index: 100;
}
