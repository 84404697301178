@import "../../scss/variables/color-plate.scss";
.AButton {
  outline: none;
  border: none;
  border-radius: 100px;
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  text-transform: capitalize;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  &.fill {
    background: linear-gradient(270.14deg, $button-1 0.1%, $green 98.75%);
    box-shadow: 0px 2px 24px $button-shadow;
  }
  &.children {
    padding: 7px 18px;
    min-width: 99px;
    .icon {
      margin-right: 10px;
    }
  }
  &.round {
    padding: 5px 18px;
    box-sizing: border-box;
    border: 2px solid $green;
    background: unset;
    color: $green;
    min-width: 99px;
    min-height: 31px;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.bigger {
    height: 54px;
    padding: 15px 30px;
  }
  &.white {
    background: white;
  }
}
