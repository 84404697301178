@import "../../../scss/respond-to";
.datePickerWrapper {
  margin-top: 41px;
  display: flex;
  justify-content: space-around;
  &__dayWrapper {
    .day {
      font-family: "Gordita", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 51.4756px;
      line-height: 73px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background: url("../../../asserts/images/calendarDay.png");

      width: 119px;
      height: 102px;
    }
  }
  &__calendarWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 27px;
  }
}
.bottomConrent {
  .month {
    margin-top: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */
    padding-bottom: 10px;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
  .eventWrapper {
    .event {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      display: flex;
      justify-content: space-between;
    }
    .reviewGame {
      cursor: pointer;
      position: relative;
      top: 7px;
      width: 81px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(270.14deg, #119490 0.1%, #16a49e 98.75%);
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
      border-radius: 100px;
      font-weight: bold;
      font-size: 12px;

      color: #ffffff;
    }
    .time {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      /* identical to box height */

      color: #ffffff;
    }
    .amount {
      font-family: Gordita;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height */

      color: #ffffff;
    }
    .winners {
      .winner {
        display: flex;
        justify-content: space-between;
        padding-top: 26px;
        > div {
          display: flex;
        }
        &__avatar {
          padding-left: 9px;
          img {
            width: 24px;
            height: 25px;
          }
        }
        &__index {
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #a7b4c4;
        }
        &__useranme {
          font-family: Gordita;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 19px;
          /* identical to box height */
          padding-left: 9px;
          color: #ffffff;
        }
        &__amount {
          display: flex;
          justify-content: center;
          align-items: center;

          font-family: Gordita;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 19px;
          /* identical to box height */

          color: #a0ebff;
          div:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@include respond-to("mobileAll") {
  .withPadding {
    padding-left: 30px;
    padding-right: 30px;
  }
  .datePickerWrapper {
    flex-wrap: wrap;
    padding-bottom: 70px;
    &__dayWrapper {
      .day {
        font-family: "Gordita", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 40.4756px;
        line-height: 73px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        background: url("../../../asserts/images/calendarDay.png");
        width: 88px;
        height: 74px;
        background-size: cover;
      }
    }
    .eventWrapper {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
@include respond-to("tablet") {
  .datePickerWrapper {
    margin-bottom: 91px;
  }
}
