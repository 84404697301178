.username {
  width: 100px;
}

.email {
  width: 200px;
}
.avatar {
  width: 100px;
  img {
    width: 50px;
    height: 50px;
  }
}
.wallet {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noWallet {
  width: 15%;

  border-bottom: 1px solid;
  font-weight: bold;
}
.copyText {
  color: #16a49e;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
}
.paginationWrapper {
  width: 40%;
  float: right;
}
