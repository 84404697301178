@import "../../../scss/respond-to";
.gameReminder {
  display: flex;
  .info {
    width: 50%;
    padding-top: 56px;
    padding-left: 48px;
    padding-bottom: 29px;
    .reminder {
      font-family: "Gordita", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */

      color: #ffffff;
    }
    .giftWrapper {
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;
      .gift {
        font-family: "Gordita", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 63px;
        /* identical to box height */
        color: #ffffff;
      }
    }
  }
  .image {
    width: 50%;
    position: relative;
    bottom: 21px;
    left: 30px;
  }
}
@include respond-to("mobileAll") {
  .gameReminder {
    .info {
      z-index: 1;
      padding-left: 30px;
      padding-bottom: 42px;
      padding-top: 38px;
      .reminder {
        white-space: nowrap;
      }
    }
    .image {
      width: 50%;
      position: relative;
      bottom: 21px;
      left: -17px;
      top: 147px;
      img {
        width: 100%;
        height: 40%;
      }
    }
  }
}
