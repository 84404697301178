@import "../../scss/variables/color-plate.scss";
.APageName {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: $text-color;
}
