.Settings {
  .row {
    margin-bottom: 26px;
  }
  .buttonWrapper {
    // margin-left: -35px;
    // margin-right: -35px;
    // padding-left: 35px;
    // padding-right: 35px;
  }
}
