.text {
  color: white;
  font-weight: normal;
  font-size: 40px;
  margin-top: 100px;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
