@import "../../scss/variables/color-plate.scss";
.inputWrapper {
  width: 100%;
  input {
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    color: $black;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    padding: 16px 13px;
    &:focus {
      outline: none;
    }
  }
  &.disabled {
    color: grey !important;

    input {
      color: grey !important;
    }
  }
}
.cMenuWrapper {
  padding: 10px;
}
