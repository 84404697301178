.date {
  width: 150px;
}
.amount {
  width: 120px;
}
.status {
  width: 120px;
}
.actions {
  width: 260px;
  .unpublish,
  .start {
    margin-right: 10px;
  }
}
.unpublished {
  color: #888888;
}
