@import "../../scss/respond-to.scss";
.confrimWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: white;
  }
}
.popup {
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  font-family: "Gordita", sans-serif;
}
.buttonWrapper {
  padding-top: 30px;
}
.text {
  font-weight: 800;
  font-size: 20px;
}
.iconClose {
  top: -20px;
  right: 4px;
  @include respond-to("mobileAll"){
    top: 10px;
    z-index: 5;
    right: 39px;
  }
}
