@import "../../scss/variables/color-plate.scss";
.c-menu {
  position: fixed !important;
  display: inline-block;
  max-width: 95vw;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
  background: $white;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0;
  // max-height: 65vh;
  z-index: 6000;
  min-width: 339px !important;
  // top: 43.2vh !important;
  // left: 35vw !important;
  display: flex;
  justify-content: center;
  &--square {
    border-radius: 0;
  }
}
