@import "../../scss/variables/color-plate.scss";
.inputWrapper {
  width: 100%;
  .beforeInput {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
  }
  input {
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    color: $black;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    padding: 16px 13px;
    &:focus {
      outline: none;
    }
  }
}
