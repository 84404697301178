@import "../../scss/variables/color-plate.scss";

.Afield {
  display: block;
  .label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: $black;
  }
  .fieldContainer {
    margin-top: 8px;
    border: 1px solid $border-color;
  }
  &.icon {
    .fieldContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      border: 1px solid $border-color;
      .fieldIcon {
        width: 20px;
        margin-left: 17px;
        cursor: pointer;
      }
      .children {
        display: flex;
        width: calc(100% - 40px);
      }
    }
  }
  .error {
    padding-top: 15px;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */

    color: #ff5353;
  }
}
