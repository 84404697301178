.label {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */
  padding-bottom: 5px;
  color: #ffffff;
}
.error {
  padding-top: 15px;
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  color: #ff5353;
}
.inputError {
  input {
    border: 1px solid red !important;
  }
}
