@import "../../../../scss/variables/color-plate.scss";
.HeaderContainer {
  border-bottom: 1px solid $border-color;
  background: white;
  .Header {
    display: flex;
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 17px;
    .navigation {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logo {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: $text-color;
      }
      .buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 40px;
      }
    }
    .button {
      $transition: 0.2s;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 43px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 10px;
      cursor: pointer;
      .label {
        margin-left: 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $text-color;
      }
      &.active,
      &:hover {
        transition: $transition;
        background: #edf7f6;
        border-radius: 4px;
        svg {
          transition: $transition;
          stroke: $green !important;
          &.fillHover {
            transition: $transition;
            fill: $green !important;
          }
        }
        .label {
          transition: $transition;
          color: $green;
        }
      }
    }
  }
}
