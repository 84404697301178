@import "../../scss/respond-to.scss";

.mainWrapper {
  display: grid;
  -ms-grid-gap: 40px 30px;
  grid-gap: 40px 40px;
  -ms-grid-auto-rows: auto;
  -ms-grid-template-rows: auto;
  grid-template-columns: 36% 61%;
  grid-template-rows: auto auto;
}
.dataWrapper {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  -ms-grid-gap: 40px 30px;
  grid-gap: 40px 19px;
  grid-auto-rows: auto;
  -ms-grid-auto-rows: auto;
  grid-template-rows: auto;
  -ms-grid-template-rows: auto;
}
.datePicker {
  padding-left: 37px;
  padding-right: 37px;
}
@include respond-to("mobileAll") {
  .mainWrapper {
    display: flex;

    flex-direction: column;
    overflow: hidden;
    grid-gap: 13px 37px;
  }
  .dataWrapper {
    grid-gap: 13px 37px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
}
@include respond-to("tablet") {
  .mainWrapper {
    display: flex;
    overflow: unset;
    grid-gap: 20px 20px;
    margin-bottom: 67px;
    .second {
      width: 100%;
      border-radius: 10px;
    }
    .gamesInfo {
      width: 47%;
    }
    // flex-direction: column;
    flex-wrap: wrap;
    .descktop-hidden {
      width: 100%;
    }
    .main-layout {
      width: 50%;
    }
    .datePicker {
      width: 50%;
    }
  }

  .dataWrapper {
    grid-gap: 13px 37px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
}
