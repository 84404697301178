@import "../../../scss/respond-to.scss";
.winningsNumbersWrapper {
  font-family: "Gordita", sans-serif;
  padding: 30px 30px 64px 30px;
  display: flex;
  flex-direction: column;
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
  }
  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */
    color: rgba(255, 255, 255, 0.45);
  }
}

.tickets {
  display: flex;
  flex-direction: column;
  &__title {
    font-family: Gordita;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */
    margin-bottom: 10px;
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.95);
  }
  .items {
    display: flex;
  }
  .itemsUser {
    display: flex;
    .ticket {
      min-width: 63px;
      min-height: 63px;
      margin-left: 7px;
    }
  }
  .ticket {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    min-width: 51.51px;
    min-height: 52.35px;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12.5687px;
    line-height: 18px;

    color: #000000;

    background: linear-gradient(
      270deg,
      #f8ab2e 4.71%,
      #f8ab2e 4.71%,
      #ffd12f 85.88%
    );
    &.last {
      color: #ffffff;
      background: linear-gradient(270deg, #f82e2e 4.71%, #ff3f3f 85.88%);
    }
    box-shadow: -7.0699px 7.85545px 17.282px rgba(0, 0, 0, 0.25);
    border-radius: 78.5545px;
    @include respond-to("mobileAll") {
      min-width: 50px !important;
      min-height: 50px !important;
    }
  }

  .disabled {
    background: rgba(255, 255, 255, 0.12);
    border: 1.57109px solid #9ae1f1;
    box-shadow: none;
    color: #a0ebff;
  }
}
.myTickets {
  margin-top: 40px;
  .title {
    font-family: "Gordita", sans-serif;
    font-style: normal;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: rgba(255, 255, 255, 0.95);
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    color: #ffffff;
  }
}
.noBorder {
  border: none;
  background: unset !important;
  box-shadow: none !important;
  color: #a0ebff !important;
}
.white {
  color: white;
}
@include respond-to("tablet") {
  .winningsNumbersWrapper {
    .tickets {
      margin-top: 25px;
    }
    flex-direction: row;
    .left {
      width: 50%;
    }
    .right {
      width: 50%;
      padding-left: 43px;
    }
  }
  .myTickets {
    margin-top: 25px;
  }
}
