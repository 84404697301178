label {
  input {
    display: none;
  }
}

.imageUploader {
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-transform: capitalize;

  color: #ffffff;

  width: 143px;
  height: 38px;
  background: linear-gradient(270.14deg, #119490 0.1%, #16a49e 98.75%);
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .label {
    display: flex;
    .text {
      padding-left: 15px;
    }
  }
}
.editImage {
  display: flex;
  .imageWrapper {
    img {
      border: 2px solid white;
      border-radius: 64px;
      width: 93px;
      height: 93px;
      object-fit: cover;
    }
  }
}
