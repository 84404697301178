@import "../../scss/respond-to.scss";
.loginWrapper {
  padding-left: 50px;
  padding-right: 50px;
  &__pageTitle {
    padding-top: 41px;
    margin-bottom: 31px;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */
    color: #ffffff;
    @include respond-to("mobileAll") {
      margin-bottom: 0px;
    }
  }
  .item {
    margin-top: 25px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
    .title {
      font-family: "Gordita", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      padding-bottom: 5px;
      color: #ffffff;
    }
    .subtitle {
      padding-top: 7px;
      font-family: "Gordita", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */
      .yellow {
        color: yellow !important;
        cursor: pointer;
      }
      color: rgba(255, 255, 255, 0.45);
    }
    color: #ffffff;
  }
  .buttonNext {
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding-left: 119px;
  }
  .buttonNextWrapper {
    margin-top: 41px;
    margin-bottom: 41px;

    &__title {
    }

    .icon {
    }
  }
  .red {
    padding-top: 15px;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: #ff5353;
  }
}
@include respond-to("mobileAll") {
  .loginWrapper {
    padding-right: 34px;
    padding-left: 34px;
  }
}
.imageHelperError {
  padding-top: 15px;
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #ff5353;
}
.haveAccount {
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  .underline {
    text-decoration: underline;
    cursor: pointer;
    padding-left: 2px;
    color: cadetblue;
  }
}
