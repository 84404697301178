@import "../../../scss/respond-to.scss";
.buyTicketWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  flex-direction: column;
  width: 100%;
  margin-bottom: 125px;
  &__text {
    color: #ffc107;
    text-align: center;
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 16px;
    line-height: 23px;

    border-bottom: 4px solid #ffc107;
    /* identical to box height */
  }
  &__numberPicker {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    .pickers {
      display: flex;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
      .circlesIcon {
        background: url("../../../asserts/icons/circles.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 48px;
        height: 9.2px;
      }
      .circles {
        padding-top: 50px;
      }
    }
    .pickerHeader {
      display: flex;
      width: calc(100% - 60px);
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;

      .left {
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
          /* identical to box height */

          color: #ffffff;
        }
        .subtitle {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          color: rgba(255, 255, 255, 0.45);
        }
      }
      .right {
        .title {
          display: flex;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
          /* identical to box height */
          .text {
            margin-left: 10px;
          }
          color: #708aa7;
        }
      }
    }
  }
}
@include respond-to("mobileAll") {
  .buyTicketWrapper {
    margin-bottom: 47px;
    &__numberPicker {
      .pickers {
        flex-wrap: wrap;
        padding-top: 0px;
        .circles {
          padding-top: 88px;
          margin-top: 35px;
        }
      }
    }
  }
}
@include respond-to("tablet") {
  .buyTicketWrapper {
    &__numberPicker {
      .pickers {
        justify-content: space-between;
        padding-top: 39px;
      }
      .circles {
        padding-top: 41px !important;
      }
    }
  }
}
