@import "../../scss/variables/color-plate.scss";

.pagination {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  border: 1px solid $border-color;
  margin-top: 20px;
}

.pageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  flex: 1 1 auto;
  border-right: 1px solid $border-color;
  &.pageNext {
    border-right: unset;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    width: 100%;
    height: 100%;
  }
  cursor: pointer;
}
.page {
  text-decoration: none;
  font-size: 14px;
}
.selected {
  background-color: rgba(170, 170, 170, 1);
  span {
    color: $white !important;
  }
}
.showMore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  flex: 1 1 auto;
  border-right: 1px solid $border-color;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $text-color !important;
    cursor: pointer;
    text-decoration: none;
  }
}
