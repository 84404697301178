@import "../../../../../../../scss/respond-to.scss";
.UpcomingGame {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  .date {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF;
  }
  .amount {
    display: flex;
    color: #a0ebff;
    font-family: "Gordita", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    justify-content: center;
    align-items: center;
    .icon {
      margin-left: 10px;
    }
  }
}