@import "../../scss/variables/color-plate.scss";
.Statistics {
  .items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .item {
      width: calc(100% / 3 - 50px);
      background: $white;
      border: 1px solid $border-color;
      box-sizing: border-box;
      padding-top: 22px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 18px;
      .title {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: $text-color;
      }
      .value {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 54px;
        color: $text-color;
      }
    }
  }
}