@import "../../scss/respond-to.scss";

.circleWrapper {
  box-sizing: border-box;
  border-radius: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.respond {
  @include respond-to("tablet") {
    &.circleWrapper {
      width: 89px !important;
      height: 89px !important;
    }
    &.smallCircle {
      width: 31.25px !important;
      height: 31.25px !important;
    }
  }
}
