@import "./variables/respond-to.scss";
@mixin respond-to($media) {
  @if $media==mobile320 {
    @media screen and (min-width: $mobile320 - 1) and (max-width: $mobile) {
      @content;
    }
  } @else if $media==mobile {
    @media screen and (min-width: $mobile) and (max-width: $mobileRotate - 1) {
      @content;
    }
  } @else if $media==mobileRotate {
    @media screen and (min-width: $mobileRotate) and (max-width: $tablet - 1) {
      @content;
    }
  } @else if $media==mobileAll {
    @media screen and (min-width: $mobile) and (max-width: $tablet - 1) {
      @content;
    }
  } @else if $media==tablet {
    @media screen and (min-width: $tablet) and (max-width: $small - 1) {
      @content;
    }
  } @else if $media==smallscreen {
    @media screen and (min-width: $small) and (max-width: $medium - 1) {
      @content;
    }
  } @else if $media==mediumscreen {
    @media screen and (min-width: $medium) and (max-width: $large - 1) {
      @content;
    }
  } @else if $media==largescreen {
    @media screen and (min-width: $large) {
      @content;
    }
  } @else if $media==container {
    @media screen and (min-width: $container) {
      @content;
    }
  }
}
.hide-mobile {
  @include respond-to(mobileAll) {
    display: none !important;
  }
}
.hide-tablet {
  @include respond-to(tablet) {
    display: none !important;
  }
}
.hide-descktop {
  @include respond-to(smallscreen) {
    display: none !important;
  }
  @include respond-to(mediumscreen) {
    display: none !important;
  }
  @include respond-to(largescreen) {
    display: none !important;
  }
}
.show-mobile,
.show-tablet,
.show-descktop {
  display: none !important;
  &-flex {
    display: none !important;
  }
}
.show-mobile {
  @include respond-to(mobileAll) {
    display: block !important;
  }
  &-flex {
    @include respond-to(mobileAll) {
      display: flex !important;
    }
  }
}
.show-tablet {
  @include respond-to(tablet) {
    display: block !important;
  }
  &-flex {
    @include respond-to(tablet) {
      display: flex !important;
    }
  }
}
.show-descktop {
  @include respond-to(smallscreen) {
    display: block !important;
  }
  @include respond-to(mediumscreen) {
    display: block !important;
  }
  @include respond-to(largescreen) {
    display: block !important;
  }
  &-flex {
    @include respond-to(smallscreen) {
      display: flex !important;
    }
    @include respond-to(mediumscreen) {
      display: flex !important;
    }
    @include respond-to(largescreen) {
      display: flex !important;
    }
  }
}
