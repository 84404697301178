.Games {
  .row {
    display: flex;
    justify-content: space-between;
    .colLeft {
      width: 339px;
    }
    .colRight {
      width: calc(100% - 339px - 30px);
      .date {
        width: 150px;
      }
      .amount {
        width: 120px;
      }
      .status {
        width: 120px;
      }
      .actions {
        width: 260px;
        .unpublish,
        .start {
          margin-right: 10px;
        }
      }

      .PaginationContainer {
        margin-top: 15px;
      }
    }
  }
}
.padding10 {
  padding: 10px;
}
