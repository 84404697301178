@import "../../scss/variables/color-plate.scss";
@import "../../scss/respond-to.scss";
.profileWrapper {
  display: flex;
  padding-top: 40px;
  div:nth-child(2) {
  }
}
.gameReminderWrapper {
  margin-left: 20px;
  // width: 100%;
  width: 32%;
  .ticketsLayout {
    // width: 34%;
  }
}
.ticketsData {
  width: 72%;
}
.profile {
  // width: 898px;
  .imageWrapper {
    position: relative;
    width: 37%;
    display: flex;
    justify-content: flex-end;
    img {
      border: 2px solid white;
      border-radius: 64px;
      width: 93px;
      height: 93px;
    }
  }
  &__info {
    .content {
      display: flex;
      width: 100%;
      position: relative;
      .userData {
        position: relative;
        left: 28px;
        width: 48%;
        @include respond-to("mobileAll") {
          width: calc(63% - 28px) !important;
          max-width: calc(63% - 28px);
          right: calc(37% + 28px);
          overflow: hidden;
        }
      }
    }
    display: flex;
    justify-content: flex-end;

    padding-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    padding-top: 36px;

    .userName {
      font-style: normal;
      font-weight: bold;
      font-size: 44px;
      line-height: 63px;
      color: $white;
      word-wrap: break-word;
      padding-right: 67px;
    }
    .signoutButton {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 21px;
      position: relative;
      color: $white;
      width: 91px;
      height: 31px;
      background: linear-gradient(270.14deg, #119490 0.1%, #16a49e 98.75%);
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
      border-radius: 100px;
    }
  }
  .active {
    color: #ffc107 !important;
    &::after {
      background: #ffc107;
      position: relative;
      z-index: 14;
      box-sizing: border-box;
      display: block;
      content: "";
      left: 17px;
      width: 113px;

      height: 4px;
      top: 23px;
      @include respond-to("mobileAll") {
        left: 0;
        width: 100%;
      }
    }
  }
}
.tabs {
  display: flex;
  justify-content: center;
  padding-bottom: 22px;
  padding-top: 12px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  @include respond-to("mobileAll") {
    justify-content: space-around;
  }
  .tab {
    width: 16%;

    cursor: pointer;
    color: $blue;
    padding-left: 0px;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height */

    text-align: center;
  }
}
@include respond-to("mobileAll") {
  .profileWrapper {
    flex-wrap: wrap;
    .ticketsData {
      width: 100%;
      order: 2;
      margin-top: 20px;
    }
    .ticketsLayout {
    }
  }
  .gameReminderWrapper {
    width: 100%;
    margin-left: 0;
  }
  .profile {
    &__info {
      .content {
        .userData {
          width: 63%;
        }
      }
      .signoutButton {
        top: 0px;
      }
    }
  }
}
@include respond-to("tablet") {
  .profileWrapper {
    flex-wrap: wrap;
    .ticketsData {
      width: 100%;
      order: 2;
      margin-top: 20px;
    }
    .ticketsLayout {
    }
  }
  .gameReminderWrapper {
    width: 100%;
    margin-left: 0;
  }
  .profile {
    &__info {
      .content {
        .userData {
          width: 80%;
        }
      }
      .signoutButton {
        top: 6px;
      }
    }
  }
}
