
@mixin font-face($name, $path, $weight: null, $style: null, $exts: otf) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	// $formats: (
	// 	otf: "opentype",
	// 	ttf: "truetype"
	// );

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		// $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');


@include font-face("Gordita", fonts/Gordita/GorditaRegular, 400, normal);
@include font-face("Gordita", fonts/Gordita/GorditaMedium, 500, normal);
@include font-face("Gordita", fonts/Gordita/GorditaBold, 700, normal);

// @include font-face("Gordita", fonts/Gordita/GorditaLight, lighter, normal);
// @include font-face("Gordita", fonts/Gordita/GorditaMedium, normal, normal);
// @include font-face("Gordita", fonts/Gordita/GorditaBlack, bolt, normal);
// @include font-face("Gordita", fonts/Gordita/GorditaBold, bolder, normal);



// @font-face {
//   font-family: 'Gordita';
//   src: url('./fonts/Gordita/Gordita\ Black.otf');
//   font-weight: normal;
//   font-style: 'normal'
// }
// @font-face {
//   font-family: 'Gordita';
//   src: url('./fonts/Gordita/Gordita\ Black\ Italic.otf');
//   font-weight: 300;
//   font-style: 'italic'
// }
// @font-face {
//   font-family: 'Gordita';
//   src: url('./fonts/Gordita/Gordita\ Bold.otf');
//   font-weight: 500;
//   font-style: 'normal'
// }
// @font-face {
//   font-family: 'Gordita';
//   src: url('./fonts/Gordita/Gordita\ Bold\ Italic.otf');
//   font-weight: 500;
//   font-style: 'italic'
// }