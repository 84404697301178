@import "./variables/index.scss";
@import "./fonts/fonts";
@import "./respond-to.scss";
@import "./_grid.scss";
@import "./icons.scss";
@import "./transition.scss";
.border-circle {
  position: relative;
  border-radius: 4px;
  z-index: 0;
  $width: 20;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.384);
  .border {
    border: 1px solid #9ae1f1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    bottom: 0;
  }

  $height: 20;
  &::before,
  &::after {
    z-index: 2;
    display: block;
    content: "";

    width: #{$width}px;
    height: #{$height}px;
    position: absolute;
    top: calc(50% - #{$height/2}px);
    border-radius: 50%;
  }

  &::before {
    left: -#{$width / 2}px;
    border: 1px solid #9ae1f1;
  }
  &::after {
    right: -#{$width / 2}px;
    border: 1px solid #9ae1f1;
  }
}
.card-container {
  background: url("../asserts/images/card.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-size: cover;
}

body {
  height: 100%;
  &.background {
    background: url("../asserts/images/Background.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
  }
}

.container {
  padding-top: 30px;
  margin: 0 auto;
  max-width: 1330px;
  // overflow: hidden;
  &.no-padding-top {
    padding-top: 0;
  }
  // @include respond-to(mobile) {
  //   width: 750px;
  // }
  // @include respond-to(mobileRotate) {
  //   width: 750px;
  // }
  @include respond-to("tablet") {
    padding-left: 18px;
    padding-right: 18px;
  }
  // @include respond-to(smallscreen) {
  //   width: 970px;
  // }
  // @include respond-to(mediumscreen) {
  //   width: 1300px;
  // }
  // @include respond-to(largescreen) {
  //   width: 1300px;
  // }
}
.flex {
  display: flex;
  &.center,
  &.items-center {
    align-items: center;
  }
  &.items-start {
    align-items: flex-start;
  }
  &.items-end {
    align-items: flex-end;
  }
  &.center,
  &.justify-center {
    justify-content: center;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-around {
    justify-content: space-around;
  }
}
.main-layout {
  // margin-top: 10px;
  background: linear-gradient(135.9deg, #558e98 15.86%, #2b2d59 85.14%);
  opacity: 0.93;
  border: 5px solid #a0ebff;
  box-sizing: border-box;
  // border-radius: 10px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  &.height100 {
    min-height: calc(100vh - 192px);
  }
}

.second-layout {
  background: linear-gradient(269.94deg, #9230b5 0.05%, #16a49e 98.82%);
  box-shadow: -4px 12px 27px rgba(16, 17, 18, 0.26);
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
}
.ReactModal__Overlay {
  background-color: rgba(21, 24, 53, 0.93) !important;
  overflow-y: auto;
  &.ReactModal__Overlay--after-open {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

html {
  height: 100%;
}
.ReactModal__Content {
  position: relative !important;
  border: none !important;
  background: none !important;
  max-width: 489px;
  width: 100%;
  // margin: 0 auto;
  /* outline: none; */
  /* padding: 20px;*/
  // padding-left: 37px !important;
  // padding-right: 37px !important;
  // padding-bottom: 20% !important;
  // padding-top: 29px !important;
  padding: 29px 37px 20px 0 !important;
  overflow-x: hidden !important;
  // height: 100% !important;
}

.mt225 {
  margin-top: calc(50vh - 320px);
}
a {
  text-decoration: none;
}
.ticket-layout {
  background: linear-gradient(269.94deg, #9230b5 0.05%, #16a49e 98.82%);
  box-shadow: -4px 12px 27px rgba(16, 17, 18, 0.26);
  border-radius: 10px;
}
.desktop-hidden {
  display: none !important;
}

@include respond-to("mobileAll") {
  .desktop-hidden {
    display: block !important;
    &.--flex {
      display: flex !important;
    }
  }
  .mobile-hidden {
    display: none !important;
  }
  .main-layout {
    margin-left: 8px;
    margin-right: 8px;
  }
  .second-layout {
    margin-left: 8px;
    margin-right: 8px;
  }
  .ReactModal__Overlay {
    z-index: 2;
    max-width: 100vw;
  }
  .ReactModal__Content {
    width: 100% !important;
    padding: 0px !important;
    inset: 0px !important;
    overflow: auto !important;
    margin: auto;
    left: unset !important;
    right: unset !important;
  }
  .padding-none-mobile {
    margin-top: 137px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
input {
  padding-left: 15px;
}
.withBorder {
  border-radius: 11px;
}
@include respond-to("tablet") {
  .desktop-hidden {
    display: block !important;
    &.--flex {
      display: flex !important;
    }
  }

  .tablet-hidden {
    display: none !important;
  }
  .no-pl-pr-tablet {
    padding-left: 0px;
    padding-right: 0px;
  }
  .no-margin-left {
    margin-left: 0px !important;
  }
}
.Toastify__toast--default {
  background: linear-gradient(
    269.94deg,
    #9230b5 0.05%,
    #16a49e 98.82%
  ) !important;
  color: white !important;
  border: 2px solid #a0ebff !important;
  border-top-left-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  padding: 8px 12px !important;
}
.Toastify__close-button--default {
  color: black !important;
  background: white !important;
  opacity: 0.3 !important;
  width: 18px !important;
  height: 18px !important;
  display: block !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 2px !important;
}
.Toastify__toast-body {
  padding-right: 10px !important;
}
.timezone_picker_modal_header {
}

.time_picker_modal_header,
.time_picker_modal_footer,
.timezone_picker_modal_header {
  background: linear-gradient(
    270.14deg,
    #119490 0.1%,
    #16a49e 98.75%
  ) !important;
}
.picker_pointer .pointer_drag {
  background: linear-gradient(
    270.14deg,
    #119490 0.1%,
    #16a49e 98.75%
  ) !important;
}
body.ReactModal__Body {
  &--open {
    overflow: hidden;
  }
}
.no-background {
  background: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.subtitle-yellow {
  font-family: Gordita;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */
  color: #ffc107;
  margin-top: 20px;
}
.respond-mobile-form-profile-page {
  @include respond-to("mobileAll") {
    padding-left: 26px;
    padding-right: 26px;
  }
}
.icon-close-confirmation {
  @include respond-to("mobileAll") {
    top: 20px;
  }
}
.ios{
  .ios-margin{ 
    margin-top: 13px;
   }
   .center-ios{
     margin-top: 10px;
   }
   .small-ball-ios{
    margin-top: 5px;
   }
}
.safari{
  .ios-margin{ 
    margin-top: 20px;
   }
   .center-ios{
     margin-top: 10px;
   }
   .small-ball-ios{
    margin-top: 10px;
   }
}

.disabled-field{
  input{
    color:grey !important;
  }
}
