@import "../../../scss/respond-to.scss";
.invites {
  display: flex;
  margin-top: 23px;
  @include respond-to("mobileAll") {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__share {
    display: flex;
    width: 70%;
    flex-direction: column;
    margin-left: 30px;
    @include respond-to("mobileAll") {
    width: 90%;
     
    }
    .title {
      font-family: Gordita;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height */

      color: #ffffff;
    }
    .content {
      .text {
        font-family: Gordita;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        /* identical to box height */
        color: #ffffff;
        margin-top: 30px;
      }
      .refer {
        display: flex;
        justify-content: space-between;
        margin-top: 11px;
        background: rgba(0, 0, 0, 0.41);
        border-radius: 4px;
        font-size: 13px;
        line-height: 19px;
        /* identical to box height */

        color: #ffffff;
        padding: 20px;
        @include respond-to("mobileAll") {
          width: 89%;
        }
      }
    }
    @include respond-to("mobileAll") {
      margin-left: 0;
    }
  }
  &__tickets {
    width: 39%;
    margin-right: 70px;
    margin-left: 45px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    top: 50px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    .iconWrapper {
      display: flex;
      position: relative;
      bottom: 39px;
      justify-content: center;
      width: 100%;
      .wrap {
        padding: 19px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
        border-radius: 100px;
        background: #f9a401;
      }
    }
    .title {
      font-family: Gordita;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      /* identical to box height */

      color: #ffffff;
    }
    .subtitle {
      font-family: Gordita;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height */

      color: #ffffff;
      margin-top: 4px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      padding-bottom: 14px;
    }
    @include respond-to("mobileAll") {
      width: 80%;
      margin-right: 0;
      margin-bottom: 100px;
      margin-left: 0px;
    }
  }
}
.ticketWrapper {
  padding-bottom: 16px;
  display: flex;
  &__tickets {
    width: 50%;
  }
  &__openTicket {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-family: Gordita;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */

    color: #a0ebff;
    margin-top: 17px;
  }
}
