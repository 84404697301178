@import "../../scss/variables/color-plate.scss";
.ATableCol {
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: $text-color;
  &.header {
    font-weight: bold;
  }
}